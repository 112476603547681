import React from "react"
import Layout from "../components/Layout"
import Menu from "../components/Menu"
import PlansContent from "../components/PlansContent"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const Plans = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Why Pyne?" />
      {/* <Menu/> */}
      <PlansContent/>
    </Layout>
  </ThemeProvider>
)

export default Plans