import styled from "styled-components"
import media from "styled-media-query"
import basicsImg from "../../images/basics.png"
import FlashImg from "../../images/flash.png"
import EnterpriseImg from "../../images/enterprise.png"

export const Section = styled.section`
    // margin-top: 100px;
` 
export const Title = styled.h2`
  margin-top: 20px;
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  line-height: 20px;
  > hr {
    width: 280px;
    border: 0;
    height: 0;
    box-shadow: 0 0 5px 1px ${props => props.theme.colors.success};
    opacity: 0.7;
    margin-top: 1px;
  }
  ${media.lessThan("small")`
    text-align: center;
    font-size: 25px;
    > hr {
    width: 180px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
  }
  `}
  ${media.between("medium", "large")`
    font-size: 35px;
    > hr {
    width:220px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
    }
    `}
`

export const Paragraph = styled.p`
    margin-top: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: ${props => props.theme.colors.white};
    
`

export const SectionOne = styled.div`
    background-color: ${props => props.theme.colors.primary};
    padding: 40px 0 40px 0 ;
`

export const TitleTwo = styled.h2`
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`

export const ParagraphTwo = styled.p`
    margin-top: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    padding: 30px 0;
    text-align: center;
    color: ${props => props.theme.colors.subtitle};
`

export const Card = styled.div`
  box-shadow: 2px 1px 15px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px;
  min-height: 410px;
  width: 300px;
  margin: 0 40px;
  margin-bottom: 40px;
  ${media.between("medium", "large")`
    width: 400px;
    height: 540px;
  `}
  ${media.lessThan("small")`
    height: 540px;
  `}
`
export const TitleCard = styled.h4`
  font-family: Josefin Sans;
  font-size: 25px;
  color: #027AD1;
`
export const ItensTitle = styled.p`
  font-family: Josefin Sans;
  font-size: 20px;
  color: #000;
  margin: 20px 0;
`
export const ItensList = styled.p`
  font-family: Josefin Sans;
  font-size: 16px;
  color: #707070;
  display: flex;
  align-items: center;
  margin: 30px 0;
`
export const Wrapper = styled.div`
  ${media.between("medium", "large")`
    margin: 0 50px;
  `}
  ${media.lessThan("small")`
    margin: 0 30px;
  `}
`
export const WrapperIMG = styled.div`
  ${media.between("medium", "large")`
    padding: 30px 0px;
    display: none;
  `}
  ${media.lessThan("small")`
    display: none;
  `}
`

export const WrapperHeaderCard = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`

export const ImgTitleBasics = styled.div`
    width: 115px;
    height: 115px;
    background-image: url(${basicsImg});
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
`
export const ImgTitleFlash = styled.div`
    width: 115px;
    height: 115px;
    background-image: url(${FlashImg});
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
`
export const ImgTitleEnterprise = styled.div`
    width: 115px;
    height: 115px;
    background-image: url(${EnterpriseImg});
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
`

export const Carrousel = styled.div`
/*    display: flex;
   flex-wrap: nowrap; */
   overflow-x: auto;
   padding-top: 50px;
`

export const WrapperMain = styled.div`

`