import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import CheckedIconComponent from "../IconsComponents/checked"

const CardPlansMobile = ({ t, i18n }) => (
    <>
{/*      <div className="container mt-6">
        <div className="columns is-desktop">
          <div className="column mt-6">
            <S.TitleTwo>{t("aboutSection.TitleTwo")}</S.TitleTwo>
            <S.ParagraphTwo>
              {t("aboutSection.paragraphThree")}<br/>{t("aboutSection.paragraphFour")}
            </S.ParagraphTwo>
          </div>
        </div>
      </div> */}
      <S.Carrousel>
            <S.WrapperMain>
              <S.Card>
                <S.WrapperHeaderCard>
                  <S.ImgTitleBasics/>
                  <S.TitleCard>{t("cards.basics")}</S.TitleCard>
                </S.WrapperHeaderCard>
                <S.ItensTitle>{t("cards.basicsIncludes")}</S.ItensTitle>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.basicsTeamLeaves")}</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">Pyne Calendar</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">Pyne Dashboard</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.basicsHolidays")}</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.basicsPayroll")}</span>
                </S.ItensList>
              </S.Card>
            </S.WrapperMain>
            <div>
              <S.Card>
                <S.WrapperHeaderCard>
                  <S.ImgTitleFlash/>
                  <S.TitleCard>Flash</S.TitleCard>
                </S.WrapperHeaderCard>
                <S.ItensTitle>{t("cards.flashIncludes")}</S.ItensTitle>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">Pyne Chart</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">Pyne Clock</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">Pyne Schedule</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.flashOvertime")}</span>
                </S.ItensList>
              </S.Card>
            </div>
            <div>
              <S.Card>
                <S.WrapperHeaderCard>
                  <S.ImgTitleEnterprise/>
                  <S.TitleCard>Enterprise</S.TitleCard>
                </S.WrapperHeaderCard>
                <S.ItensTitle>{t("cards.enterpriseIncludes")}</S.ItensTitle>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.enterpriseWorkspace")}</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.enterpriseMyTeam")}</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.enterpriseMyOrganization")}</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">{t("cards.enterpriseMyCompany")}</span>
                </S.ItensList>
                <S.ItensList>
                  <CheckedIconComponent/>
                  <span className="ml-5">Pyne Stories</span>
                </S.ItensList>
              </S.Card>
            </div>
      </S.Carrousel>
    </>
  )
  
  export default withTrans(CardPlansMobile)
