import styled from "styled-components"
import media from "styled-media-query"
import backgroundWhyCard from "../../images/cardWhyImage.png"
import plansImg from "../../images/plans-image.png"

export const Section = styled.section`
    // margin-top: 100px;
` 
export const Title = styled.h2`
  margin-top: 20px;
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  line-height: 50px;
  > hr {
    width: 280px;
    border: 0;
    height: 0;
    box-shadow: 0 0 5px 1px ${props => props.theme.colors.success};
    opacity: 0.7;
    margin-top: 1px;
  }
  ${media.lessThan("small")`
    text-align: center;
    font-size: 25px;
    > hr {
    width: 180px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
  }
  `}
  ${media.between("medium", "large")`
    font-size: 35px;
    > hr {
    width:220px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
    }
    `}
`

export const Paragraph = styled.div`
    > .content {
      font-weight: 300;
      margin: 0;
    }
    margin-top: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: ${props => props.theme.colors.white};
    span {
      font-weight: bold;
    }
`

export const SectionOne = styled.div`
    background-image: linear-gradient(to bottom, rgba(36,129,197,0.7) 0%,rgba(36,129,197,0.7) 100%), url(${plansImg});
    background-size: cover;
    background-position: center;

    padding: 100px 0 100px 0;
    ${media.between("medium", "large")`;
      //font-size: 35px;
      padding: 50px 0 100px 0;
    `}
    ${media.lessThan("small")`
      padding: 0px
    `}
`

export const TitleTwo = styled.h2`
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`

export const ParagraphTwo = styled.p`
    margin-top: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    padding: 30px 0;
    color: ${props => props.theme.colors.subtitle};
`
export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  height: 53px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #7EBA2A;
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  border-radius: 26px;
  transition: color 0.5s;
  transition: background-color 0.5s;
  margin-bottom: 40px;

  &:hover {
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.info};
  }
  ${media.lessThan("small")`
      width: 190px;
      height: 44px;
      //margin-top: 30px;
  `}
`

export const ButtonDown = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  height: 53px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ED7878;
  color: #fff;
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  border-radius: 26px;
  transition: color 0.5s;
  transition: background-color 0.5s;
  //margin-bottom: 40px;
  margin-top: 50px;
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: #7ad1a6; 
  }
  ${media.lessThan("small")`
      width: 190px;
      height: 44px;
      margin-top: 30px;
  `}
`

export const TitleB = styled.h4`
  font-family: Josefin Sans;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 50px;
`

export const ParagraphB = styled.p`
  margin-top: 15px;
  font-family: Josefin Sans;
  font-size: 16px;
`
export const CardImage = styled.div`
  //background-image: url(${backgroundWhyCard});
  width: 427px;
  height: 697px;
  border-radius: 20px;
  padding-right: 30px;
  padding-left: 20px;
  background: 
    linear-gradient(
      rgba(255, 255, 255, 0.20), 
      rgba(255, 255, 255, 0.20)
    ),
    url(${backgroundWhyCard});
    background-position: ${props => props.bgImg ? "left -478px bottom -214px;" : "" };
    background-position: ${props => props.bgImgR ? "left -820px bottom -214px;" : "" };
    ${media.between("medium", "large")`;
      //font-size: 35px;
      display: none;
    `}
    ${media.lessThan("small")`
      display: none;
    `}
`

export const CardContent = styled.div`
  font-family: Josefin Sans;
  font-size: 18px;
  background-color: white;
  padding: 5px;
  line-height: 40px;
  margin-top: 50px;
  > p::first-letter {
    color: #027AD1;
    font-size: 30px;
  }
  ${media.lessThan("small")`
  margin-top: 0px;
    `}
/*   position: relative;
  top: 250px;
  left: 8px; */
  ${media.between("medium", "large")`;
      //font-size: 35px;
      margin: 0 50px;
  `}
`

export const TableBG = styled.div`
  background-color: ${props => props.theme.colors.primary};
  padding-bottom: 150px;
  //padding: 80px 0;
  .item {
    color: white;  
    font-family: Josefin Sans;
    font-size: 18px;
    font-weight: 400;
    padding: 30px 0;
  }
  .text-module {
    text-align: center;
  }
  ${media.between("medium", "large")`
    display: none;
  `}
  ${media.lessThan("small")`
    display: none;
  `}
  
`

export const TitleCard = styled.h3`
    color: white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: 40px;
`
export const Card = styled.div`
    background: #FFFFFF;
    border-radius: 15px;
    width: 70%;
    // height: 327px;
    display: flex;
    justify-content: space-around;
    //margin-bottom: 20px;
`

export const UlTitle = styled.ul`
  padding: ${props => props.inside ? "0" : "40px 0;" };
  width: 200px;
`
export const UlTitleSecond = styled.div`
  //padding: 10px 0;
  display: flex;
  align-items: center;
  //width: 200px;
`
export const Ul = styled.ul`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const UlInside = styled.div`
  //padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const UlInsideOne = styled.div`
  //padding: 20px 0;
  width: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Item = styled.li`
  font-family: Josefin Sans;
  font-size: 18px;
  font-weight: 400;
  margin: 23px 0;
  > span {
    background: #FF8A00;
    border-radius: 20px;   
    font-family: Josefin Sans;
    font-size: 12px; 
    padding: 5px 15px;
    color: #fff;
    margin-left: 10px;
    margin-bottom: 10px;
  }
`

export const ItemSecond = styled.li`
  font-family: Josefin Sans;
  font-size: 18px;
  list-style-type: none;
  //font-weight: 400;
  //margin: 23px 0;
  > span {
    background: #F55F44;
    border-radius: 20px;   
    font-family: Josefin Sans;
    font-size: 12px; 
    padding: 5px 15px;
    color: #fff;
    margin-left: 10px;
    margin-bottom: 10px;
    display: inline;
  }
`

export const Icon = styled.li`
  margin: 10px 0;
`
export const IconInside = styled.div`
  margin: 10px 0;
`

export const TitleList = styled.h6`
  color: #027AD1;
  font-family: Josefin Sans;
  font-size: 16px;
  margin-bottom: 20px;
`

export const TitleSecond = styled.h2`
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  ${media.lessThan("small")`
    font-size: 25px;
    line-height: 40px;
  `}
`

export const ParagraphSecond = styled.p`
    margin-top: 16px;
    margin-bottom: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    padding: 30px 0;
    text-align: center;
    color: ${props => props.theme.colors.subtitle};
`

export const Wrapper = styled.div`
  ${media.between("medium", "large")`
    margin: 0 50px;
  `}
  ${media.lessThan("small")`
    margin: 0 30px;
  `}
`

export const WrapperIMG = styled.div`
  margin: 0 30px;
`

export const WrapperCardPlans = styled.div`
  display: none;
  ${media.between("medium", "large")`
    display: flex;
    width: 100%;
  `}
  ${media.lessThan("small")`
    display: inline;
  `}
`