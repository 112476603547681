import React from "react"
import { withTrans } from "../../i18n/withTrans"

import CheckedIcon from "../../icons/checked.svg"

const CheckedIconComponent = ({ t, i18n }) => (
  <div>
      <CheckedIcon/>
  </div>
)

export default withTrans(CheckedIconComponent)