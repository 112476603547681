import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PlansImg from "../PlansImg"
import CheckedIconComponent from "../IconsComponents/checked"
import CardPlansMobile from "../CardPlansMobile"
import Menu from "../Menu"

const PlansContent = ({ t, i18n, bgImg, bgImgR }) => (
  <>
    <S.Section>
      <S.SectionOne>
        <Menu/>
        <S.Wrapper>
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-flex is-justify-content-center is-flex-direction-column">
                <S.Title>{t("plansPage.titleOne")}<br/>{t("plansPage.titleTwo")}<br/> {t("plansPage.titleThree")}</S.Title>
                <S.Paragraph>
                  <p className="content">{t("plansPage.content")}<br/><br/></p>
                </S.Paragraph>
              </div>
              <div className="column is-flex is-justify-content-center is-align-items-center">
                {/* <PlansImg/> */}
              </div>
            </div>
          </div>
        </S.Wrapper>
      </S.SectionOne>
      <S.TableBG>
        <div className="container">
          <div class="columns">
            <div class="column item text-module">{t("table.titleModules")}</div>
            <div class="column item">{t("table.titleFeatures")}</div>
            <div class="column item is-half">{t("table.titlePlans")}</div>
          </div>
        </div>

        <div className="container mt-5">
          {/* Basics */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titleBasics")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>{t("cards.basicsTeamLeaves")}</S.Item>
                  <S.Item>PyneCalendar</S.Item>
                  <S.Item>PyneDashboard</S.Item>
                  <S.Item>{t("cards.basicsHolidays")}</S.Item>
                  <S.Item>{t("cards.basicsPayroll")}</S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>
          
          {/* ANALYTICS */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titleAnalitycs")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>PyneChart<span>Beta</span></S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>

          {/* TIME & ATTENDANCE */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titleTime")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>PyneClock</S.Item>
                  <S.Item>PyneSchedule</S.Item>
                  <S.Item>{t("cards.flashOvertime")}</S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>

          {/* WorkSpace */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titleWorkspace")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>{t("cards.enterpriseWorkspace")}</S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>

          {/* Culture */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titleCulture")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>{t("cards.enterpriseMyTeam")}</S.Item>
                  <S.Item>{t("cards.enterpriseMyOrganization")}</S.Item>
                  <S.Item>{t("cards.enterpriseMyCompany")}</S.Item>
                  <S.Item>Pyne Stories<span>{t("cards.soon")}</span></S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>

          {/* Flex */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titleFlex")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>{t("cards.worklife")}</S.Item>
                  <S.Item>{t("cards.vacation")}</S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>

          {/* PERFORMANCE */}
          <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard className="mb-5">{t("table.titlePerformace")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle>
                  <S.Item>{t("cards.growth")}<span>{t("cards.soon")}</span></S.Item>
                  <S.Item>{t("cards.performance")}<span>{t("cards.soon")}</span></S.Item>
                  <S.Item>{t("cards.survey")}<span>{t("cards.soon")}</span></S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.TitleList>Basics</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Flash</S.TitleList>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                <S.TitleList>Enterprise</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.TitleList>EX</S.TitleList>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                  <S.Icon><CheckedIconComponent/></S.Icon>
                </S.Ul>
              </S.Card>
            </div>
          </div>

          {/*  PERFORMANCE */}
{/*           <div className="columns is-flex is-justify-content-center">
            <div className="column is-flex is-justify-content-flex-end">
              <S.TitleCard>{t("table.titlePerformace")}</S.TitleCard>
            </div>
            <div className="column is-four-fifths py-0">
              <S.Card>
                <S.UlTitle inside>
                  <S.Item>{t("cards.growth")}<span>{t("cards.soon")}</span></S.Item>
                  <S.Item>{t("cards.performance")}<span>{t("cards.soon")}</span></S.Item>
                  <S.Item>{t("cards.survey")}<span>{t("cards.soon")}</span></S.Item>
                </S.UlTitle>
                <S.Ul>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                  <S.Icon>-</S.Icon>
                </S.Ul>
                <S.Ul>
                  <S.IconInside><CheckedIconComponent/></S.IconInside>
                  <S.IconInside><CheckedIconComponent/></S.IconInside>
                  <S.IconInside><CheckedIconComponent/></S.IconInside>
                </S.Ul>
                <S.Ul>
                  <S.IconInside><CheckedIconComponent/></S.IconInside>
                  <S.IconInside><CheckedIconComponent/></S.IconInside>
                  <S.IconInside><CheckedIconComponent/></S.IconInside>
                </S.Ul>
              </S.Card>
            </div>
          </div> */}
        </div>
      </S.TableBG>
      <S.WrapperCardPlans>
        <CardPlansMobile/>
      </S.WrapperCardPlans>
      <div className="column is-flex is-justify-content-center is-align-items-center">
        <AniLink to="/contact">
          <S.ButtonDown>{t("introSection.buttonRequest")}</S.ButtonDown>
        </AniLink>
      </div>
    </S.Section>
  </>
)

export default withTrans(PlansContent)